<template>
    <div>
        <!--begin::balance-->
        <b-card no-body class="mb-5">
            <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
                    {{ $t('advanced_filter') }}
                </b-button>
            </b-card-header>
            <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                    <div class="m-form m-form--fit m--margin-bottom-20">
                        <div class="row">
                            <div class="form-group col-md-4 mb-2 mt-2">
                                <label>{{$t('customer_balance_report.customer')}}</label>
                                <!-- <select name="" id="customer" v-model="filters.customer_id" class="custom-select">
                                    <option v-for="row in customers" :value="row.id" :key="row.id">
                                        {{ row.fullname }}
                                    </option>
                                </select> -->
                                <multiselect v-model="customer"
                                             :placeholder="$t('customer_balance_report.customer')"
                                             label="name" track-by="id"
                                             :options="customers"
                                             :multiple="false"
                                             :taggable="false"
                                             :show-labels="false"
                                             :show-no-options="false"
                                             :show-no-results="false"
                                             @search-change="getCustomers($event)">
                                </multiselect>
                            </div>
                            <div class="form-group col-md-4 mb-2 mt-2">
                                <label for="from_date">{{$t('from_date')}}</label>
                                <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
                            </div>

                            <div class="form-group col-md-4 mb-2 mt-2">
                                <label for="to_date">{{$t('to_date')}}</label>
                                <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
                            </div>
                            <div class="form-group col-md-6 mb-2 mt-2">
                                <label>{{$t('customer_balance_report.assigned_staff')}}</label>
                                <!-- <select name="" id="employee_id" v-model="filters.employee_id" class="custom-select">
                                    <option v-for="row in users" :value="row.id" :key="row.id">
                                        {{ row.full_name }}
                                    </option>
                                </select> -->
                                <multiselect v-model="employee"
                                             :placeholder="$t('customer_balance_report.assigned_staff')"
                                             label="full_name"
                                             track-by="id"
                                             :options="users"
                                             :multiple="false"
                                             :taggable="false"
                                             :show-labels="false"
                                             :show-no-options="false"
                                             :show-no-results="false"
                                             @search-change="getUsers($event)">
                                </multiselect>
                            </div>

<!--                            <div class="form-group col-md-6 mb-2 mt-2">-->
<!--                                <label>{{$t('status')}}</label>-->
<!--                                <select name="" id="status" v-model="filters.status" class="custom-select">-->
<!--                                    <option v-for="row in status_list" :value="row.id" :key="row.id">-->
<!--                                        {{ row.title }}-->
<!--                                    </option>-->
<!--                                </select>-->
<!--                            </div>-->

                            <div class="col-md-3 mb-2 mt-2">
                                <label>&ensp;</label>
                                <b-form-checkbox size="lg" v-model="filters.hide_zero" name="check-button">
                                    {{$t('customer_balance_report.hide_zero_balance')}}
                                </b-form-checkbox>
                            </div>
                            <div class="col-md-3 mb-2 mt-2">
                                <label>&ensp;</label>
                                <b-form-checkbox size="lg" v-model="filters.show_details" name="check-button">
                                    {{$t('customer_balance_report.show_details')}}
                                </b-form-checkbox>
                            </div>

                            <div class="form-group d-inline-flex col-md-6 mt-10">
                                <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                                    {{$t('display_report')}}
                                </button>
                                <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="resetFilter">
                                    <i class="fas fa-trash-restore"></i> {{$t('reset_search')}}
                                </button>
                            </div>
                        </div>
                    </div>
                </b-card-body>
            </b-collapse>
        </b-card>
        <div class="card card-custom mb-5">
            <div class="card-body">
                <div class="row mb-10">
                    <div class="col-12 mt-10" id="summaryTableTitle">
                        <h4 class="text-center">{{ $t('customer_balance_report.balances_report_group_by_all')}}</h4>
                        <h5 class="text-center">
                            <span v-if="date_time_now">{{ $t('time') }} {{date_time_now}}</span>
                        </h5>
                        <h5 class="text-center">
                            <span v-if="filters.from_date">{{ $t('from') }} {{filters.from_date}} </span>
                            <span v-if="filters.to_date"> {{ $t('to') }} {{filters.to_date}}</span>
                        </h5>
                    </div>
                    <div class="d-flex col-12 justify-content-end p-4">
                        <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                            <download-excel
                                    class="dropdown-item"
                                    :fetch="getDataList"
                                    :fields="json_fields"
                                    :name="$t('customer_balance_report.customer_balance_report')+'.xls'">
                                <i class="la la-file-excel"></i>{{$t('excel')}}
                            </download-excel>
                            <button class="dropdown-item" @click="printData('summaryTable')">
                                <span><i class="fa fa-print"></i> <span>{{$t('print')}}</span></span>
                            </button>
                            <button class="dropdown-item" @click="printPDFData('summaryTable')">
                                <span><i class="fa fa-file-pdf"></i> <span>{{$t('export_pdf')}}</span></span>
                            </button>
                        </b-dropdown>

                    </div>
                </div>
                <div class="col-12 mt-10">
                    <div class="table-responsive">
                        <table class="table table-bordered" :id="'summaryTable'">
                            <thead>
                            <tr id="myRow1">
                                <th>{{$t('customer_balance_report.code')}}</th>
                                <th>{{$t('customer_balance_report.account_no')}}</th>
                                <th>{{$t('customer_balance_report.name')}}</th>
                                <th>{{$t('customer_balance_report.employee')}}</th>
                                <th>{{$t('customer_balance_report.debit_before')}}</th>
                                <th>{{$t('customer_balance_report.debit_after')}}</th>
                                <th>{{$t('customer_balance_report.balance_before')}}</th>
                                <th>{{$t('customer_balance_report.balance_after')}}</th>
                                <th>{{$t('customer_balance_report.total_sales')}}</th>
                                <th>{{$t('customer_balance_report.total_refund')}}</th>
                                <th>{{$t('customer_balance_report.net_sales')}}</th>
                                <th>{{$t('customer_balance_report.total_payments')}}</th>
                                <template v-if="filters.show_details">
                                    <th>{{$t('customer_balance_report.phone')}}</th>
                                    <th>{{$t('customer_balance_report.address')}}</th>
                                    <th>{{$t('customer_balance_report.category')}}</th>
                                </template>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(row, index) in data" :key="'c'+getRandom()+index">
                                <td>{{row.code}}</td>
                                <td>{{row.account_no}}</td>
                                <td><span style="display: block;word-break: break-word;">{{row.name}}</span></td>
                                <td>{{row.employee}}</td>
                                <td>{{row.debit_before}}</td>
                                <td>{{row.debit_after}}</td>
                                <td>{{row.balance_before}}</td>
                                <td>{{row.balance_after}}</td>
                                <td>{{row.total_sales}}</td>
                                <td>{{row.total_refund}}</td>
                                <td>{{row.net_sales}}</td>
                                <td>{{row.total_payments}}</td>
                                <template v-if="filters.show_details">
                                    <td>{{row.phone}}</td>
                                    <td>{{row.address}}</td>
                                    <td>{{row.category}}</td>
                                </template>
                            </tr>

                            </tbody>
                            <tfoot>

<!--                            <tr v-if="dataTotal">-->
<!--                                <td colspan="8">{{$t('total')}}</td>-->
<!--                                <th>{{ dataTotal.sum_net_sales}}</th>-->
<!--                                <th>{{ dataTotal.sum_total_refund}}</th>-->
<!--                                <th>{{ dataTotal.sum_total_payments}}</th>-->
<!--                                &lt;!&ndash; <th>{{ dataTotal.sum_settlements}}</th> &ndash;&gt;-->
<!--                                <th>{{ dataTotal.sum_balance}}</th>-->
<!--                                <template v-if="filters.show_details">-->
<!--                                    <td></td>-->
<!--                                    <td></td>-->
<!--                                    <td></td>-->
<!--                                </template>-->
<!--                            </tr>-->
                            </tfoot>
                        </table>
                    </div>
                </div>
                <div class="col-12 text-center" v-if="page">
                    <button class="btn btn-warning" @click="loadMore">
                        <p class="mb-0">
                            <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>
                            {{$t('load_more')}}
                        </p>
                    </button>
                </div>
            </div>
        </div>
      <div class="selector-export" id="selectorExport"></div>
      <div class="export-container">
        <div id="sectionForExportHeader" class="section-for-export">
          <report-main-header :print-type="'CustomersBalance'"></report-main-header>
        </div>
      </div>
        <!--end::balance-->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import exportPDFInnerAction from "@/core/plugins/export-jspdf-inner-action";
    import ReportMainHeader from "@/view/content/printing-heads/ReportMainHeader.vue";
    import cssStypeForPrintReport from "@/core/config/mix/cssStypeForPrintReport";

    export default {
        name: "index-client-balances",
        components: {ReportMainHeader},
        data() {
            return {
                mainRoute: '/reports/customer-balance-report',
                mainRouteExport: '/reports/customer-balance-report/data-list',

                date_time_now: null,
                dataList: [],
                dataTotal: [],
                data: [],
                customers: [],
                status_list: [],
                users: [],
                procedures: [],

                filters: {
                    customer_id: null,
                    from_date: null,
                    to_date: null,
                    employee_id: null,
                    status: null,
                    hide_zero: false,
                    show_details: false,
                },
                customer: null,
                employee: null,
                page: 0,
            }
        },
        watch: {
            customer: function (val) {
                if (val) {
                    this.filters.customer_id = val.id;
                } else {
                    this.filters.customer_id = null;
                }
            },
            employee: function (val) {
                if (val) {
                    this.filters.employee_id = val.id;
                } else {
                    this.filters.employee_id = null;
                }
            },
        },
        computed: {
            json_fields: function () {
                let fields = {};
                fields[this.$t('customer_balance_report.code')] = 'code';
                fields[this.$t('customer_balance_report.account_no')] = 'account_no';
                fields[this.$t('customer_balance_report.name')] = 'name';
                fields[this.$t('customer_balance_report.employee')] = 'employee';
                fields[this.$t('customer_balance_report.debit_before')] = 'debit_before';
                fields[this.$t('customer_balance_report.debit_after')] = 'debit_after';
                fields[this.$t('customer_balance_report.balance_before')] = 'balance_before';
                fields[this.$t('customer_balance_report.balance_after')] = 'balance_after';
                fields[this.$t('customer_balance_report.total_sales')] = 'total_sales';
                fields[this.$t('customer_balance_report.total_refund')] = 'total_refund';
                fields[this.$t('customer_balance_report.net_sales')] = 'net_sales';
                fields[this.$t('customer_balance_report.total_payments')] = 'total_payments';
                if (this.filters.show_details) {
                    fields[this.$t('customer_balance_report.phone')] = 'phone';
                    fields[this.$t('customer_balance_report.address')] = 'address';
                    fields[this.$t('customer_balance_report.category')] = 'category';
                }

                return fields;
            },

        },

        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.clients_reports"), route: '/clients/reports'}, {title: this.$t("MENU.customer_balance_report")}]);

            this.date_time_now = this.$moment().format('DD-MM-YYYY H:mm');

            this.getStatus();

        },
        methods: {
            ...cssStypeForPrintReport,
            doFilter() {
                this.page = 1;
                this.getData();
            },
            resetFilter() {
                this.filters.customer_id = null;
                this.filters.from_date = null;
                this.filters.to_date = null;
                this.filters.employee_id = null;
                this.filters.status = null;
                this.filters.hide_zero = false;
                this.filters.show_details = false;
                this.customer = null;
                this.employee = null;

                this.getData();
            },

            dataReport() {
                this.setReportLog('excel', 'customer balance report');
                return this.data;
            },
            loadMore() {
                this.page = this.page ? (this.page + 1) : 1;
                this.getData();
            },
            getData() {
                let _filter = {...this.filters};
                _filter.hide_zero = _filter.hide_zero ? 1 : 0;
                _filter.show_details = _filter.show_details ? 1 : 0;
                ApiService.get(this.mainRoute, {params: {..._filter, page: (this.page ? this.page : 1)}}).then((response) => {
                    this.data = response.data.data.data;
                    this.dataTotal = response.data.data.total;
                });
            },

            getCustomers(filter) {
                if(filter && filter.length >= 3)
                    ApiService.get("base/dependency/customers", {params:{filter: filter}}).then((response) => {
                        this.customers = response.data.data;
                    });
            },
            getUsers(filter) {
                if(filter && filter.length >= 3){
                    ApiService.get(`base/dependency/users`,{params:{filter: filter}}).then((response) => {
                        this.users = response.data.data;
                    });
                }else{
                    this.users = [];
                }
            },
            getStatus() {
                ApiService.get("base/dependency/natural_account").then((response) => {
                    this.status_list = response.data.data;
                });
            },

            getDataList() {
                this.setReportLog('excel', 'customer balance report');
                // let _filter = this.filter;
                // _filter.hide_zero = _filter.hide_zero ? 1 : 0;
                // _filter.show_details = _filter.show_details ? 1 : 0;
                // return ApiService.get(this.mainRouteExport,{params:{..._filter}}).then(response => {
                //     return response.data.data;
                // }).catch(e => {
                //     console.log(e);
                // });
                let _data = this.data;
                // _data.push({
                //     code: this.$t('total'),
                //     account_no: '',
                //     name: '',
                //     manual_status: '',
                //     employee: '',
                //     balance_before: '',
                //     total_sales: '',
                //     total_refund: '',
                //     net_sales: this.dataTotal.sum_net_sales,
                //     total_payments: this.dataTotal.sum_total_payments,
                //     settlements: this.dataTotal.sum_settlements,
                //     balance: this.dataTotal.sum_balance,
                //     phone: '',
                //     address: '',
                //     category: '',
                // });
                return _data;
            },
            getRandom() {
                return Math.floor(Math.random() * 10000);
            },
          printPDFData(tableId) {
            this.setReportLog('pdf', 'customer balance report');
            exportPDFInnerAction(tableId , 'pdf', this.$t('customer_balance_report.customer_balance_report'), this.$t('customer_balance_report.customer_balance_report'));
          },
          printData(tableId) {
            this.setReportLog('pdf', 'customer balance report');
            let divToPrint = document.getElementById(tableId);
            let divTitleToPrint = document.getElementById('sectionForExportHeader');
            let newWin = window.open("");
            newWin.document.write('<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">');
            newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>");
            newWin.document.write('<style>');
            newWin.document.write(this.getCssStyleForPrintReport());
            newWin.document.write('</style>');
            newWin.document.write(divTitleToPrint.outerHTML + divToPrint.outerHTML);
            setTimeout(() => {
              newWin.print();
              // newWin.close();
            }, 100)
          },
            /**
             * register log
             */
            setReportLog(key, description) {
                ApiService.post("reports/system_activity_log/log_type", {
                    key: key,
                    description: description,
                });
            }
        },
    };
</script>
<style>
.html2pdf__overlay{
  opacity: 0 !important;
  z-index: -1000;

}
.selector-export{
  opacity: 0 !important;
  z-index: -1000;
  width: 0.0001px;
  height: 0.0001px;
  overflow: auto;
}
</style>
